<template>
  <div class="ticket">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent"/>
    <div class="content">
      <d-lable class="contentTable" :tableData='tableData' :columnData='columnData'>
        <template v-slot:deviceType="{ row }">
          <span>{{ $map.deviceTypes[row.deviceType] || '' }}</span>
        </template>
        <template v-slot:payMethod="{ row }">
          <span>{{ $map.payMethods[row.payMethod] || '' }}</span>
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager" @change="getItemList" />
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import moment from 'moment'
import { onDownload, filterDayDate, disabledDayDate } from "@/utils/utils";
export default {
  components: {
    DSearch,
    DLable,
    DPaging,
  },
  data () {
    return {
      childList: [],
      searchData: {
        searchItem: [
          {
            label: '选择店铺',
            value: 'shopIds',
            type: 'linkageSelect',
            placeholder: '',
            options: [],
            filterable: true,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'childList'
            }
          },
          {
            label: "充值渠道",
            value: "payMethod",
            type: "commonSelect",
            placeholder: "",
            options: this.$map.getter('payMethods')
          },
          {
            label: "时间范围",
            value: "date",
            type: "scopeDate",
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期",
            noClear: true,
            options: {
              onPick: filterDayDate.bind(this),
              disabledDate: disabledDayDate.call(this, 365 * 24 * 60 * 60 * 1000)
            }
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {
          shopIds: [],
          date: [],
          payMethod: ''
        },
      },
      columnData: [
        { prop: 'corporateName', label: '店铺名称' },
        { prop: 'name', label: '设备名称' },
        { prop: 'deviceType', label: '设备类型', slotProp: true },
        { prop: 'payMethod', label: '充值渠道', slotProp: true },
        { prop: 'amount', label: '充值金额' },
        { prop: 'createTime', label: '充值时间' },
      ],
      tableData: [],
      maxDate: null,
      minDate: null,
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      isShow: false,
      id: ''
    };
  },
  created () {
    this.setInitDate()
    this.searchEvent()
    this.getShopList()
  },
  methods: {
    setInitDate () {
      const endDate = moment().format('YYYY-MM-DD')
      const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD')
      this.searchData.params.date = [startDate, endDate]
    },
    searchEvent () {
      this.pager.page = 1
      this.getItemList()
    },

    resetEvent () {
      this.setInitDate()
      this.getItemList()
    },

    findElementById (data = [], targetId) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.id === targetId) {
          return item;
        }
        if (item.childList && item.childList.length > 0) {
          const result = this.findElementById(item.childList, targetId);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },

    setParams () {
      const { date, shopIds, payMethod } = this.searchData.params
      const [startTime, endTime] = date || []
      let currentShop = this.findElementById(this.childList, shopIds[shopIds.length - 1])
      return {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        startTime: startTime ? startTime + ' 00:00:00' : null,
        endTime: endTime ? endTime + ' 23:59:59' : null,
        shopCode: currentShop ? currentShop.shopCode : null,
        payMethod,
        payStatus: 0
      }
    },
    getItemList () {
      const data = this.setParams()
      this.$api.merchant.getCreateNoApi(data).then(({ code, data }) => {
        if (code !== 200) return
        this.pager.count = data.totalCount || 0
        this.tableData = data.chargeRecords || []
      })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.busapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    }
  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 155px);
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }

  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
  }
}

.d-paging {
  text-align: center;
}
</style>